const brand = [
  "Alothailand",
  "Banilaco",
  "Bodyshop",
  "CentralBeauty",
  "Clarins",
  "Clarins2",
  "DcashPro",
  "GmHealthCheck",
  "Hourglass",
  "Huapla",
  "Jewellista",
  "KcInterFood",
  "Kis",
  "Lolane",
  "Mille",
  "Nissin",
  "Petme",
  "Samyanmitrtown",
  "Seasun",
  "Silomedge",
  "Snakebrandfamily",
  "Thaniyagroup",
  "Yongcharoen",
  "YugenThailand",
  "Aesop",
];
const code = [
  "ALO",
  "CB",
  "CR",
  "CR2",
  "DCASH",
  "GHC",
  "HG",
  "HUAP",
  "JWL",
  "KCIF",
  "KIS",
  "LOLANE",
  "ML",
  "NS",
  "PETME",
  "SYM",
  "SEASUN",
  "SNB",
  "TG",
  "YCR",
  "YGT",
  "AU",
  "BC",
  "BS",
  "SE",
];

const getBrandCode = () => {
  let host = window.location.hostname;
  let b = host.split("-")[0];
  console.log(b);
  let codeIndex = brand.findIndex((el) => el == b);
  if (codeIndex == -1) return "DEV";
  return code[codeIndex];
};
export { getBrandCode };
